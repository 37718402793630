// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-servicio-js": () => import("../src/templates/servicio.js" /* webpackChunkName: "component---src-templates-servicio-js" */),
  "component---src-templates-tratamiento-js": () => import("../src/templates/tratamiento.js" /* webpackChunkName: "component---src-templates-tratamiento-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aparatologia-js": () => import("../src/pages/aparatologia.js" /* webpackChunkName: "component---src-pages-aparatologia-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paquetes-js": () => import("../src/pages/paquetes.js" /* webpackChunkName: "component---src-pages-paquetes-js" */),
  "component---src-pages-servicios-js": () => import("../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */)
}

